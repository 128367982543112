<template>
  <div>
    <loading-screen :is-loading="isSaving"></loading-screen>

    <v-container>
      <div class="page-header">
        <h1>We need just a few details to setup your account...</h1>
      </div>

      <v-stepper class="mb-6" v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            Personal Details
          </v-stepper-step>

          <v-divider />

          <v-stepper-step :complete="step > 2" step="2">
            <!-- Self Certification -->
            Account Type
          </v-stepper-step>

          <v-divider />

          <v-stepper-step :complete="step > 3" step="3">
            <!-- Investment Preferences -->
            Terms And Conditions
          </v-stepper-step>

          <v-divider />

          <v-stepper-step :complete="step > 4" step="4">
            ID Verification
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <!-- Personal Details -->
      <div v-if="step == 1" class="personal-details">
        <personal-details
          :details="details"
          @update-details="updateDetails"
          @next="goToNextStep"
        ></personal-details>
      </div>
      <!-- / Personal Details -->

       <!-- Certification -->
       <div v-if="step == 2" class="certification">
        <!-- <certification
          :details="details"
          @update-details="updateDetails"
          @next="goToNextStep"
          @previous="goToPreviousStep"
        ></certification> -->

        <account-type
          :details="details"
          @update-details="updateDetails"
          @next="goToNextStep"
          @previous="goToPreviousStep"
        ></account-type>
      </div>
      <!-- / Certification -->

      <!-- Investment Preferences -->
      <!-- <div v-if="step == 2" class="investment-preferences">
        <investment-preferences
          :details="details"
          @update-details="updateDetails"
          @next="goToNextStep"
          @previous="goToPreviousStep"
        ></investment-preferences>
      </div> -->
      <!-- / Investment Preferences -->

      <!-- Terms And Conditions -->
      <div v-if="step == 3" class="terms-and-conditions">
        <terms-and-conditions
          :details="details"
          @update-details="updateDetails"
          @save="save"
          @previous="goToPreviousStep"
        ></terms-and-conditions>
      </div>
      <!-- / Terms And Conditions -->
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Certification from "../../../../components/Account/Setup/Certification.vue";
import AccountType from "../../../../components/Account/Setup/AccountType.vue";
import InvestmentPreferences from "../../../../components/Account/Setup/InvestmentPreferences.vue";
import PersonalDetails from "../../../../components/Account/Setup/PersonalDetails.vue";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import users from "../../../../api/users";
import Forms from "../../../../mixins/Forms";
import { mapActions } from "vuex";
import TermsAndConditions from "../../../../components/Account/Setup/TermsAndConditions.vue";

export default {
  name: "Setup",
  mixins: [Forms],
  components: {
    PersonalDetails,
    InvestmentPreferences,
    // Certification,
    AccountType,
    LoadingScreen,
    TermsAndConditions,
  },
  data() {
    return {
      step: 1,

      details: {
        job_title: "",
        phone_number: "",
        address_line_one: "",
        address_line_two: "",
        city: "",
        postcode: "",
        country: "United Kingdom",
        account_type: null,
        business_name: ""
        // investor_type: null,
        // default_investment_preference: null,
      },

      isSaving: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
    }),
  },
  created() {
    if (this.user && this.user.account_setup) {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapActions("auth", {
      updateUser: "updateUser",
    }),

    updateDetails(details) {
      this.details = {
        ...this.details,
        ...details,
      };
    },

    goToPreviousStep() {
      this.step -= 1;
    },

    goToNextStep() {
      this.step += 1;
    },

    save() {
      let vm = this;
      vm.isSaving = true;
      users
        .setupAccount(this.details)
        .then((r) => {
          vm.isSaving = false;
          vm.$toast.success("Account setup successfully");
          vm.updateUser(r.data);
          vm.$router.push("/");
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          let errors = vm.getErrorsAsArray(e);
          if (errors.length) {
            vm.$toast.error(_.first(errors));
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.page-header {
  padding: 20px 0;
}
</style>