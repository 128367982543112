<template>
  <div>
    <base-card>
      <v-card-text>
        <div class="pa-2">
          <div class="mb-3">
            <b>How would you like us to manage your accounts?</b>
          </div>

          <v-row>
            <v-col v-for="(preference, i) in preferences" :key="i" :cols="4">
              <base-card
                class="card-select"
                @click.native.prevent="selectPreference(preference.value)"
              >
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <v-card-title>{{ preference.label }}</v-card-title>
                    <v-card-subtitle>{{
                      preference.description
                    }}</v-card-subtitle>
                  </div>
                  <div class="flex-shrink-1 pr-5 pl-2 d-flex align-center">
                    <v-icon v-if="isSelected(preference.value)">
                      mdi-check-circle
                    </v-icon>
                    <v-icon v-else> mdi-checkbox-blank-circle-outline </v-icon>
                  </div>
                </div>
              </base-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </base-card>

    <!-- Actions -->
    <div class="actions">
      <v-btn dark @click.prevent="previous" class="mr-3">
        <v-icon left>mdi-chevron-left</v-icon>
        Previous
      </v-btn>

      <v-btn dark @click.prevent="next"
        >Next
        <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <!-- / Actions -->
  </div>
</template>

<script>
const PREFERENCES = [
  {
    value: 0,
    label: "Discretionary",
    description: "We'll do everything on your behalf",
  },
  {
    value: 1,
    label: "Part-Discretionary",
    description: "Require some approval",
  },
  {
    value: 2,
    label: "Approval Required",
    description: "Always require approval",
  },
];

export default {
  props: ["details"],
  data() {
    return {
      detailsLocal: {
        ...this.details,
      },
      preferences: PREFERENCES,
    };
  },
  methods: {
    previous() {
      this.$emit("update-details", this.detailsLocal);
      this.$emit("previous");
    },

    next() {
      if (this.detailsLocal["default_investment_preference"] == null) {
        this.$toast.error("Please select an option");
        return false;
      }

      this.$emit("update-details", this.detailsLocal);
      this.$emit("next");
    },

    isSelected(value) {
      return this.detailsLocal["default_investment_preference"] === value;
    },

    selectPreference(value) {
      this.detailsLocal["default_investment_preference"] = value;
    },
  },
};
</script>

<style scoped lang="scss">
.card-select {
  background: #e6f2fc !important;
  &:hover {
    background: #daebfb !important;
  }
}

.actions {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>