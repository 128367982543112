<template>
  <div>
    <v-form ref="form" @submit.prevent="next">
      <!-- Personal Details -->
      <base-card class="mb-5">
        <v-card-text>
          <b>Personal Details</b>
          <v-row>
            <v-col :cols="12" :md="6">
              <v-text-field label="Phone" v-model="detailsLocal.phone_number" :rules="phoneNumberRules" required />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field label="Job Title" v-model="detailsLocal.job_title" :rules="jobTitleRules" required />
            </v-col>
          </v-row>
        </v-card-text>
      </base-card>
      <!-- / Personal Details -->

      <!-- Address -->
      <base-card>
        <v-card-text>
          <b>Address</b>

          <!-- Row 1 -->
          <v-row>
            <v-col :cols="12" :md="6">
              <v-text-field label="Address Line One" v-model="detailsLocal.address_line_one"
                :rules="addressLineOneRules" required />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field label="Address Line Two" v-model="detailsLocal.address_line_two" />
            </v-col>
          </v-row>
          <!-- / Row 1 -->

          <!-- Row 2 -->
          <v-row>
            <v-col :cols="12" :md="6">
              <v-text-field label="City" v-model="detailsLocal.city" :rules="cityRules" required />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field label="Postcode" v-model="detailsLocal.postcode" :rules="postcodeRules" required />
            </v-col>
          </v-row>
          <!-- / Row 2 -->

          <!-- Row 3 -->
          <v-row>
            <v-col :cols="12" :md="6">
              <v-select v-model="detailsLocal.country" :items="countryNames" :rules="countryRules" label="Country" required>
              </v-select>

              <!-- <v-text-field label="Country" v-model="detailsLocal.country" :rules="countryRules" required /> -->
            </v-col>
          </v-row>
          <!-- / Row 3 -->
        </v-card-text>
      </base-card>
      <!-- / Address -->

      <!-- Actions -->
      <div class="actions">
        <v-btn dark @click.prevent="next">Next
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <!-- / Actions -->
    </v-form>
  </div>
</template>

<script>
import country from 'country-list-js';
const _ = require('lodash');

export default {
  props: ["details"],
  data() {
    return {
      detailsLocal: {
        ...this.details,
      },

      phoneNumberRules: [(v) => !!v || "Phone number is required"],
      jobTitleRules: [(v) => !!v || "Job title is required"],

      addressLineOneRules: [(v) => !!v || "Address line one is required"],
      cityRules: [(v) => !!v || "City is required"],
      postcodeRules: [(v) => !!v || "Postcode is required"],
      countryRules: [(v) => !!v || "Country is required"],
    };
  },
  computed: {
    countryNames() {
      // return country.names();
      return _.sortBy(country.names(), name => name[0].toLowerCase());
    }
  },
  methods: {
    next() {
      let isValid = this.$refs["form"].validate();
      if (isValid) {
        this.$emit("update-details", this.detailsLocal);
        this.$emit("next");
      }
    },
  },
};
</script>

<style scoped>
.actions {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>