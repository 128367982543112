import axios from 'axios';

export default {
    setupAccount(params) {
        return axios.post(window.API_BASE + '/account-setup', params);
    },

    forgotPassword(email) {
        return axios.post(window.API_BASE + '/forgot-password', {
            email: email
        });
    },

    resetPassword(email, password, passwordConfirmation, token) {
        return axios.post(window.API_BASE + '/reset-password', {
            'email': email,
            'password': password,
            'password_confirmation': passwordConfirmation,
            'token': token
        });
    }
}