<template>
  <div>
    <div class="pa-3">
      <b>Please read and accept our terms and conditions</b>
    </div>

    <base-card>
      <v-card-text>

        <!-- Terms -->
        <div class="pa-3">
          <p>
            <b>Stock Purchase Agreement</b>
          </p>
          <p>
            <b>Made between:</b>
          </p>
          <p>
            (A) The Consignee: <br>
            Name: Maia Luxury Ltd (“MLL"), a company registered in UK, no:07071536 <br>
            Registered Address: 4 Old Park Lane, Mayfair, London, W1K 1QW, England, UK.
          </p>
          <p>
            <b>And</b>
          </p>
          <p>
            (B) The Consignor: <br>
            Name: {{ nameForContract }}<br>
            Address: {{ addressForContract }}
            <br>
          </p>
          <p>
            Having read and understood this document in full, we agree to enter into this Agreement incorporating the
            terms and conditions stated herein (which in-cludes the Appendix hereto) (together the “Agreement”).
          </p>
          <p>
            <b>Commencement Date:</b> {{ commencementDate }}
          </p>

          <p><strong>1.</strong>&nbsp; &nbsp; <strong>Background</strong>:&nbsp;</p>
          <p>All parties hereto wish to enter into this Agreement, as defined by the terms and conditions herein, whereby
            the Consignor agrees to make available financing, in order that MLL can purchase, hold on consignment and
            resell genuine authentic Hermes Bags in consideration for a share of the financial profit gained from the
            resale of those bags as more fully defined herein. &nbsp;</p>
          <p><strong>2.</strong>&nbsp; &nbsp; <strong>Definitions and Interpretation</strong>&nbsp;</p>
          <ol>
            <li>&nbsp;<strong>“Business Days” &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp;&nbsp;</strong>A&nbsp;day other than a Saturday, Sunday or public holiday in England.
            </li>
            <li><strong>&nbsp;“ Confidential Information”&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong>All and any information
              of whatever nature which is communicated in writing, orally or electronically from one party to the other
              party disclosed under or in relation to this Agreement including but not limited to any term of this
              Agreement and sensitive business information of either party,&nbsp;their respective
              Clients&nbsp;and/or&nbsp;their respective businesses.</li>
            <li><strong>“Force Majeure”</strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Any event affecting the performance of any provision in this Agreement or
              the ability of any party or other person or any third party from performing their duties under this
              Agreement arising from or attributable to acts, events, omissions, or accidents which are beyond the
              reasonable control of a party including but not limited to acts of God, war, terrorism, riot, civil
              commotion, malicious damage, compliance with any law or governmental order, rule, regulation or direction,
              accident, fire, flood, storm, lock-outs or industrial action, or failure or shortage of power supplies.</li>
          </ol>
          <p><strong>2.1</strong>&nbsp; In this Agreement unless the context otherwise requires or except as expressly
            provided the singular shall include the plural and vice versa.&nbsp;</p>
          <p><strong>2.2</strong>&nbsp; Words denoting any gender shall include all genders &nbsp;</p>
          <p><strong>2.3</strong>&nbsp; Any words following the terms <strong>“including”, “include”, “in particular”,
              “for example”</strong>, or any similar expression shall be construed as illustrative and shall not
            &nbsp;limit the sense of the words, description, definition, phrase or term preceding those terms.&nbsp;</p>
          <p><strong>2.4</strong>&nbsp; Any reference to a person includes an individual, their firm, partnership, company
            or corporation, as applicable.&nbsp;</p>
          <p><strong>2.5</strong>&nbsp; Any reference to a party or parties shall mean the parties to this
            Agreement.&nbsp;</p>
          <p><strong>2.6</strong>&nbsp; Any reference to any regulation, rule, statute, legislation or statutory provision
            includes a reference to that regulation, rule, statute, Legislation, statutory provision as from time to time
            amended, extended or re-enacted.&nbsp;</p>
          <p><strong>2.7</strong>&nbsp; A reference to a document is a reference to that document as varied or novated (in
            each case, other than in breach of provisions of this Agreement) at any time.&nbsp;</p>
          <p><strong>2.8</strong>&nbsp; Any reference to an Appendix, Clauses or Sub-Clauses is to an Appendix, Clause or
            Sub-Clause of this Agreement. &nbsp;</p>
          <p><strong>2.9</strong>&nbsp; The Clause and Appendix headings in this agreement are for convenience only and
            shall not affect the interpretation of this Agreement&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>2.10</strong> Any obligation on a Party not to do something includes an obligation not to allow that
            thing to be done.&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>&nbsp;3.</strong>&nbsp; &nbsp; <strong>Understanding</strong>&nbsp;</p>
          <p><strong>3.1&nbsp;</strong> Each party warrants and undertakes to the other that it has full authority to
            enter into this Agreement and is not bound by any agreement with any third party that adversely affects this
            agreement, save that it is acknowledged and agreed that the Consignee is bound by other similar arrangements
            to this Agreement; and that it has and will maintain throughout the term, all necessary powers, authority and
            consents to enter into and fully perform its obligations under this agreement.&nbsp;</p>
          <p><strong>3.2&nbsp;</strong>Each party hereto enters into this Agreement of unfettered free will, having sought
            independent legal advice and accepts all the terms and conditions herein without reservation, including all
            and any restrictions and limitations contained therein.</p>
          <p><strong>3.3</strong> Each party will bear their own costs and expenses relating to the general working of
            this Agreement.&nbsp;</p>
          <p><strong>4.</strong>&nbsp; &nbsp; <strong>MLL’s obligations:</strong></p>
          <p><strong>4.1&nbsp;</strong>To present the consignor with options to purchase bags on behalf of the consignor,
            that MLL in their experienced commercial opinion are attractive for resale.</p>
          <p><strong>4.2&nbsp;</strong> To hold secure all money it receives from the Consignor and ensure that all bags
            purchased with that capital are allocated to the Consignor within 24 hours of purchase, as evidenced by the
            issuance of a <strong>Stock Purchase Invoice</strong>, an example of which is set out in <strong>Appendix
              1</strong>, and simultaneously consigned to the Consignee for resale as per the <strong>Consignment
              Notice</strong> set out in <strong>Appendix 4</strong>. Such Consignment Notice will include a description
            of where the bag is stored.</p>
          <p><strong>4.3.&nbsp;</strong>To use its best endeavours to sell each and every bag it holds on consignment as
            soon as practically possible, subject to attaining a resale price that achieves an expected profit margin as
            set out in the initial offer email<strong>&nbsp;</strong>and as from time to time sought by MLL, at their sole
            discretion.</p>
          <p><strong>4.4</strong> To store all bags held on consignment at its own risk and expense, insured as per the
            <strong>Certificate of Insurance</strong> as set out in
            <strong>Appendix</strong><strong>&nbsp;</strong><strong>2</strong> hereto.
          </p>
          <p><strong>4.5</strong> Following a resale, to provide a <strong>Notice of Sale Confirmation</strong>, as set
            out in <strong>Appendix 3</strong> hereto, to the Consignor within 24 hours of the resale of each and every
            bag it holds on consignment, as more fully defined in Clause 6 below.</p>
          <p><strong>4.6</strong> &nbsp;To provide the consignor with a quarterly (every three calendar months) account,
            certified by MLL’s qualified accountant, detailing the cost of stock purchased, the price it was sold for and
            the profit accrued to date, that relates to the financial commitment of the consignor under this agreement.
          </p>
          <p><strong>4.7&nbsp;</strong> To allow the Consignor to inspect their bags held on consignment in the presence
            of MLL in any of the storage locations around the globe twice annually having provided 1 month’s notice of
            their intent to do so to MLL, provided always that the Consignor pays in advance for all MLL's expenses in
            facilitating the same, which will include but is not limited to business class flights, suitable hotel
            accommodation and other ancillary expenses incurred, as notified by MLL to the consignor at the relevant time.
          </p>
          <p><strong>4.8&nbsp;</strong> To buy back all unsold bags from the Consignsor, within 120 days of receipt or
            despatch of a valid notice of Termination in accordance with Clause 9 below.</p>
          <p><strong>5. &nbsp; &nbsp;Consignor’s Obligations</strong></p>
          <p><strong>5.1</strong> Once a purchase has been agreed, to provide sufficient capital in order to facilitate
            that purchase.</p>
          <p><strong>5.2&nbsp;</strong>To exclusively allow MLL 120 calendar days to buy any unsold bags the Consignee
            holds for the Consignor, following receipt or despatch of a valid Notice of Termination, for the same cost
            price for which each bag was brought originally.</p>
          <p><strong>6.</strong>&nbsp; &nbsp; <strong>Commission</strong>&nbsp;</p>
          <p><strong>6.1</strong>&nbsp; In consideration for the provision of capital and grant of the consignment,
            <strong>MLL</strong> will pay the Consignor a commission equal to 50% of the profit received on each and every
            bag resold.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p><strong>6.2&nbsp;</strong> Profit will be the difference between the purchase price, as indicated on the
            relevant <strong>Stock Purchase Invoice</strong>, issued by the Consignee on purchase, an example of which is
            set out at <strong>Appendix 1</strong>, and the confirmed resale price, as set out clearly on the
            <strong>Notice of Confirmation of sale</strong>, an example of which is set out at <strong>Appendix
              3</strong>, for that same bag.
          </p>
          <p><strong>6.3</strong>&nbsp; Commissions will become disclosable from MLL to the Consignor within 24 hours of
            receipt of full payment in cleared funds to MLL, for each sale which relates to their product held by MLL on
            consignment, save that MLL shall have an initial grace period of 7 Business Days in which to make their
            payments.&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>6.4</strong> All payments made under this Clause 6 shall be made by same day electronic bank transfer
            in cleared funds to the same bank account from which the original receipt of funds was made.</p>
          <p><strong>6.5</strong> All payments are exclusive of VAT, unless otherwise stated. &nbsp;All parties are
            responsible for all taxes and audits within their resident tax country for any liability that accrues. &nbsp;
          </p>
          <p>&nbsp;</p>
          <p><strong>6.6 &nbsp;</strong>Either party agrees that they will be liable &nbsp;to pay the other a late payment
            penalty in addition to the initial Fee outlined above, to be calculated at 5% above LIBOR per 7 Business Day
            period for each and every 7 Business Day period that any relevant Fee remains unpaid beyond the initial 7
            Business Day grace period as outlined in 6.3 above.</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>&nbsp;7.</strong>&nbsp; &nbsp; &nbsp;<strong>Limitation of Liability</strong>&nbsp;</p>
          <p><strong>7.1</strong>&nbsp; Neither party shall be liable to any other under this Agreement for any loss,
            damage, cost, expense or other claim for compensation arising as a direct or indirect result or breach or
            non-performance of this Agreement due to a Force Majeure Event.&nbsp;</p>
          <p><strong>7.2&nbsp;</strong> The Consignor shall indemnify and keep indemnified MLL from and against all
            claims, damages, losses, costs (including reasonable legal costs), expenses, demands or liabilities arising
            out of any claim including but not limited to those arising from the Consignor’s use of Confidential
            Information provided by MLL, intentionally or otherwise. &nbsp;&nbsp;</p>
          <p><strong>7.3</strong>&nbsp; Either party’s maximum aggregate liability to the other under or in connection
            with this Agreement, whether liability arises in contract, tort or otherwise, shall in no circumstances exceed
            the total sum MLL holds on consignment as set out in Appendix 1.<strong>&nbsp;</strong></p>
          <p>&nbsp;</p>
          <p><strong>8.</strong>&nbsp; &nbsp; <strong>Term and Termination</strong>&nbsp;</p>
          <p><strong>8.1</strong>&nbsp; This Agreement shall continue for an initial and noncancellable minimum term of
            12 months and shall continue thereafter until terminated by either party.</p>
          <p><strong>8.2</strong>&nbsp; Either party may immediately terminate this Agreement by giving written notice to
            the other party if that other party;&nbsp;</p>
          <ol>
            <li>Fails to pay any amount due under this Agreement on the due date for payment and remains in default not
              less than 7 Business Days after being notified in writing; or</li>
          </ol>
          <ol>
            <li>Remains in breach of a material obligation under this Agreement (other than failure to pay any amounts due
              under this agreement) for more than 7 Business days after the injured party has notified the party in breach
              in writing of the breach and requiring its remedy; or</li>
          </ol>
          <ol>
            <li>Enters into liquidation or any composition with its creditors, is served a statutory demand or has a
              resolution passed to wind up ( except an amalgamation or construction) or has a receiver, administrator,
              administrative receiver appointed over all or any parts of its assets; or</li>
          </ol>
          <ol>
            <li>Ceases permanently to trade or threaten to do so.</li>
          </ol>
          <p><strong>8.3</strong> Following the minimum term, either party may terminate this Agreement by giving written
            notice&nbsp;to the other party if;&nbsp;</p>
          <ol>
            <li>a party Does anything which in the reasonable opinion of the other party could or does damage the
              reputation of that party or otherwise brings that party into disrepute; or&nbsp;</li>
          </ol>
          <ol>
            <li>a party deems that the activity, being the subject matter of this agreement, in their opinion, is no
              longer practicable or financially viable for whatever reason.&nbsp;</li>
          </ol>
          <p><strong>9.</strong>&nbsp; &nbsp; <strong>Consequences of Termination</strong>&nbsp;</p>
          <p><strong>9.1</strong>&nbsp; Termination of this Agreement will not affect the rights or obligations of either
            party which have accrued as at that date.&nbsp;</p>
          <p><strong>9.2</strong>&nbsp; Following Termination for whatever reason:&nbsp;</p>
          <p>Each party will promptly return to the other all material of the other in its possession or control, save for
            bags held on consignment and delete permanently all electronic files and material of the other which it has
            stored or saved in any format whatsoever; and&nbsp;</p>
          <p><strong>9.3&nbsp;</strong>Each shall pay the other any sums that are outstanding and to be accounted for
            under this Agreement within 7 Business Days of the Termination date.&nbsp;</p>
          <p><strong>9.4</strong> All Parties shall, at all times thereafter, refrain from any conduct that would be
            inconsistent with, or be likely to cause confusion with respect to, the nature of their determined business
            relationship.&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>9.5</strong> Save as provided above, termination of this Agreement shall be without prejudice to the
            rights of a party in respect of an antecedent breach of this Agreement by the other party prior to the date of
            termination.&nbsp;</p>
          <p><strong>9.6</strong> Following a valid notice of termination, MLL is bound within 30 calendar days to use its
            best endeavours, without guarantee, to sell all the stock it holds on consignment, in the usual manner and in
            accordance with the terms and conditions of this agreement, paying the investor their share of the profit
            accrued from those sales as well as returning the capitol sum invested.&nbsp;</p>
          <p><strong>9.7</strong> Should any of the bags not be sold for a profit within 30 calendar days of the notice of
            termination being received or sent by MLL, negating any possible share of profit for those unsold bags, MLL
            will only reimburse the original purchase price within 120 calendar days.&nbsp;</p>
          <p><strong>9.8&nbsp;</strong>Once all resale profits from bags held and sold as well as capital sum provided, as
            set out in Appendix 1, have been returned to the Consignor, the contract is deemed terminated, subject to 15.8
            below.</p>
          <p>&nbsp;</p>
          <p><strong>10. &nbsp; &nbsp;Confidentiality</strong>&nbsp;</p>
          <p><strong>10.1&nbsp;</strong> All parties shall keep confidential and not at any time either during the term or
            at any time thereafter use or disclose without permission of any other party any Confidential Information
            either in whole or in part, or use it for any purposes other than those in accordance with its obligations
            under this Agreement.&nbsp;</p>
          <p><strong>10.2</strong>&nbsp; The provisions of this clause do not apply to information already in the public
            domain, information which comes into the public domain other than as a result of a breach of this Agreement by
            the other party, information received from a third party who is free to disclose it, information which is
            independently developed by either party without breaching this Agreement, or information which either party is
            required to disclose pursuant to any statute, regulation or order of any court or regulatory authority.&nbsp;
          </p>
          <p><strong>11.</strong>&nbsp; &nbsp; <strong>Data Protection</strong>&nbsp;</p>
          <p><strong>11.1</strong>&nbsp; All parties undertake to comply with all relevant data protection laws and to
            keep confidential and not to use, reproduce and keep without prior agreement from the other party any data
            relating to the other party or any client or customer of MLL which it may obtain or receive in connection with
            this Agreement, other than information publicly disseminated and which it has collected independently. Subject
            to any Limitation of Liability contained within this Agreement, each party agrees to indemnify the other party
            for any non-compliance with any applicable data protection legislation.&nbsp;</p>
          <p><strong>11.2&nbsp;</strong> Each party to this Agreement will warrant to use and store any data it receives
            from the other in a manner consistent with UK legislation, best industry practice and in accordance with any
            reasonable requests of the other party.&nbsp;</p>
          <p><strong>12.</strong>&nbsp; &nbsp; <strong>Dispute Resolution</strong>&nbsp;</p>
          <p><strong>12.1</strong>&nbsp; Neither party shall commence any legal proceedings in connection with any dispute
            until the procedures set out in this clause have been completed, unless it is necessary for that party to do
            so in order to protect or preserve any cause of action it may have against the other party, in which case the
            proceedings shall be served on the other party and stayed by consent until such time as the parties have
            completed the said procedures.&nbsp;</p>
          <p><strong>12.2</strong>&nbsp; The parties shall attempt in good faith to resolve any Dispute promptly by
            negotiation which shall be conducted as follows:&nbsp;</p>
          <p>Any dispute in the first instance be referred by a written notice of dispute, from the referring party to the
            other party (the <strong>Notice of Dispute</strong>). &nbsp;Within 10 Business Days after receipt of the
            Notice of Dispute, the receiving part shall deliver to the other party a written response (the
            <strong>Response</strong>). &nbsp;Both the Notice of Dispute and the Response shall include a statement of the
            position of the party preparing the Notice of Dispute or Response and shall annex any documents or information
            relied on by that party. Within 5 Business Days of receiving a Response to a Notice of Dispute, or if no
            Response is delivered, within 5 Business Days of the date upon which the time for delivery of a Response
            expired, authorised representatives from each party shall meet at a mutually acceptable time and place to
            discuss and try to reach agreement to resolve the Dispute.&nbsp;
          </p>
          <p><strong>12.3</strong>&nbsp; All negotiations and discussions pursuant to this clause will be deemed made on a
            without prejudice basis and remain confidential and shall be treated as compromise and settlement negotiations
            for the purpose of any applicable rules of evidence.&nbsp;</p>
          <p><strong>12.4</strong>&nbsp; If the Dispute is not settled by negotiation in accordance with the initial
            meeting between the parties as determined by this clause, within 10 Business Days after the date of that
            meeting, either party may initiate proceedings in respect of the Dispute, subject to the other provisions of
            this Agreement.&nbsp;</p>
          <p><strong>12.5</strong>&nbsp; Unless this Agreement has already been terminated, the parties shall continue to
            perform their obligations under the Agreement regardless of the nature of the Dispute and notwithstanding the
            referral of the Dispute for resolution pursuant to this clause.&nbsp;</p>
          <p><strong>12.6</strong>&nbsp; Nothing in this Agreement shall prevent either party seeking interim relief in
            any court.&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;<strong>13.</strong>&nbsp; &nbsp; &nbsp;<strong>Amendments and modifications</strong>&nbsp;</p>
          <p><strong>13.1</strong>&nbsp; This Agreement constitutes the entire agreement between the parties and
            supersedes and extinguishes all previous drafts, agreements, arrangements and understandings between them,
            whether written or oral, relating to its subject matter. &nbsp;Each party agrees that in entering into this
            Agreement it does not rely on, and it shall have no remedies in respect of any representation or warranty
            (whether made innocently or negligently) that is not set out in this Agreement. &nbsp;No party shall have any
            claim for innocent or negligent misrepresentation based upon any statement in this agreement.&nbsp;</p>
          <p><strong>13.2</strong>&nbsp; This Agreement may not be amended or modified except by mutual agreement between
            all parties in writing duly signed on behalf of each party or by their authorised representatives.&nbsp;</p>
          <p><strong>14.</strong>&nbsp; &nbsp; &nbsp;<strong>Notices</strong>&nbsp;</p>
          <p>Any notice required or permitted to be given under this Agreement shall be in writing and shall be sent to
            the address of the other party’s office, contained within this Agreement or to such other address as the
            recipient may designate by notice to the other party in accordance with this clause. &nbsp;Any such notice
            shall be delivered personally, pre-paid recorded delivery service or by email (unless an out of office or mail
            undeliverable message is received).&nbsp;</p>
          <p><strong>15.&nbsp;</strong> <strong>General</strong>&nbsp;</p>
          <p><strong>15.1</strong>&nbsp; This Agreement may be executed in counterparts both of which taken together shall
            constitute one and the same instrument and any one or more of the parties may enter into this Agreement by
            executing a counterpart.&nbsp;</p>
          <p><strong>15.2</strong>&nbsp; This Agreement is personal to the parties and neither party shall be entitled to
            assign the benefit of this agreement or any of its obligations hereunder to any other party.&nbsp;</p>
          <p><strong>15.3</strong>&nbsp; No waiver by any other party of any breaches by any other party shall be
            construed as a waiver of any subsequent breach of the same or any other provision of this Agreement nor shall
            any delay or omission on the part of either party to exercise or avail itself of any right or power that it
            has or may have hereunder operate as a waiver of any breach or default.&nbsp;</p>
          <p><strong>15.4</strong>&nbsp; If any provision of this Agreement is held by any competent authority to be
            invalid or unenforceable in whole or in part, the validity of the other provisions of this Agreement and the
            remainder of the provision in question shall not be affected and the parties shall immediately enter into good
            faith negotiations to amend such provision in such a way as amended it is valid and legal and to the maximum
            extent possible carries out the original intent of the parties as to the point or points in question. &nbsp;
          </p>
          <p><strong>15.5</strong>&nbsp; This Agreement shall not create or imply the existence of any joint venture,
            partnership or agency between the parties to this Agreement nor any arrangement which would impose liability
            on one party for acts and omissions of the other.&nbsp;</p>
          <p><strong>15.6</strong>&nbsp; Nothing in this agreement confers the right on the Consignor to inspect the
            Consignee’s financial accounts and sale information and nothing in this Agreement obliges the Consignee to
            disclose her accounts or sales information to the Consignor.</p>
          <p><strong>15.7&nbsp;</strong> No term of this Agreement shall be enforceable under the Contracts (Rights of
            Third Parties) Act 1999 by a third party.</p>
          <p>&nbsp;</p>
          <p><strong>15.8</strong>&nbsp; No formal public announcement or press release in connection with the signature
            or subject matter of this Agreement shall be made or issued without the prior written approval of all
            parties.&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>15.9</strong>&nbsp; On termination of this Agreement the following clauses shall survive and continue
            in full force and effect: 9, 10, 11 and 17. &nbsp;</p>
          <p><strong>16.</strong> &nbsp; &nbsp;&nbsp;<strong>Good Faith</strong>&nbsp;</p>
          <p>All parties agree to act in good faith towards one another in the performance of their obligations under this
            Agreement.&nbsp;</p>
          <p>&nbsp;&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>&nbsp;17.</strong> &nbsp; &nbsp;&nbsp;<strong>Non circumvention</strong>&nbsp;</p>
          <p><strong>17.1&nbsp;</strong>The Consignor agrees not to circumvent or compete with MLL by conducting business
            directly with any of MLL’s clients or potential clients without the consent of MLL.</p>
          <p><strong>17.2&nbsp;</strong>Either party<strong>&nbsp;</strong>shall not (and shall procure that any other
            company, person or business associated in any way with them shall not) in any manner, directly or indirectly,
            circumvent or attempt to circumvent the operation of this Agreement so as to otherwise deprive the other of
            any of the benefits intended under or pursuant to this Agreement. &nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>17.3</strong>&nbsp; All sale information is confidential and not disclosable and may only be utilised
            by the MLL. If the Consignor discloses the details of any sale information to any third party it will be
            deemed a “<strong>Third Party disclosure</strong>”. As a result of this breach of confidentiality and
            loss&nbsp;of potential business,&nbsp;the Consignor&nbsp;agrees to indemnify&nbsp;MLL&nbsp;for any losses
            incurred.&nbsp;&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>17.4</strong>&nbsp; Should MLL disclose or make an Introduction, directly or indirectly,
            intentionally or otherwise, to the Consignor of one of the Purchasers of their product, the Consignor shall
            not make (directly or indirectly) any approach or contact or otherwise seek to deal with that purchaser or any
            of his family friends or associates, otherwise than through and with the prior consent of MLL.&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>17.5</strong>&nbsp; Further, the Consignor undertakes that it will not solicit or seek to entice away
            the contacts from MLL for the purpose of providing the same or a similar nature of the product or service
            either party supplies unless expressly agreed in writing by MLL.&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>17.6</strong>&nbsp; The restrictions in clauses this clause shall apply during the term of this
            Agreement and for a period of 24 months after the termination of this Agreement howsoever such termination
            arises.&nbsp;</p>
          <p><strong>17.7</strong> Both Parties irrevocably acknowledges and accepts that the restrictions in clauses
            &nbsp;10 and 17 above are a fair and reasonable means to protect the rights of other party under this
            Agreement including its right to protect its goodwill, its contacts and its confidential information and
            associated know how.&nbsp;</p>
          <p><strong>18.</strong>&nbsp; &nbsp; <strong>Governing&nbsp;Law&nbsp;and Jurisdiction</strong>&nbsp;</p>
          <p>This Agreement shall be governed, construed and interpreted in accordance with the laws of England and Wales
            and any dispute or claim that arises out of or in connection with this Agreement or its subject matter or
            formation shall be subject to the exclusive jurisdiction of the English Courts.&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <!-- <p>Signed by <strong>Lawrence Tosh</strong> &nbsp;..............................................</p>
          <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
          <p>Signed by <strong>Nicola Morris&nbsp; &nbsp;</strong></p>
          <p>For and on behalf of &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
          <p><strong>Maia Luxury Limited &nbsp;&nbsp;</strong>.............................................</p>
          <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Director</p> -->
          <p><strong>APPENDIX 1</strong></p>
          <p>&nbsp;<strong>An example of a Stock Purchase Invoice</strong></p>
          <p>&nbsp;</p>
          <table>
            <tbody>
              <tr>
                <td>
                  <p>QUANTITY</p>
                </td>
                <td>
                  <p>DESCRIPTION</p>
                </td>
                <td>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;AMOUNT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>1</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                </td>
                <td>
                  <p>HERMEs KELLY DANSE VERSO BLUSH WITH ROSE JAIPURE INSIDE EVERCOLOR WITH PALLADIUM HARDWARE&nbsp;</p>
                  <p>stock purchase&nbsp;</p>
                </td>
                <td>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;£8,130.69</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;&nbsp;</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp;</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
                  <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;&nbsp;</p>
                  <p>&nbsp; &nbsp;&nbsp;</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <p>&nbsp;<strong>APPENDIX 2</strong>&nbsp;</p>
          <p><strong>Certificate of insurance</strong></p>
          <p><strong>DEALER’S INSURANCE&nbsp;</strong></p>
          <p><strong>To be read in conjunction with the Wording</strong>&nbsp;</p>
          <p>NAMED LOCATIONS:&nbsp;</p>
          <p>PERIOD: April 2021- April 2022</p>
          <p>SUMS INSURED AND LIMITS OF LIABILITY Please note Specific&nbsp;sub limits in the policy wording&nbsp;</p>
          <p>XL Catlin Insurance Company UK Ltd Subscribing to Binder B0334SC3342020423&nbsp;</p>
          <p>CUSXL110413A Maia Luxury, Limited &amp;/or Bags of Luxury.&nbsp;</p>
          <p>Location 1: Coombe End Farm House, Goring Heath, Reading RG8 7TE&nbsp;</p>
          <p>Location 2: Lane Crawford, IFC, Hong Kong.&nbsp;</p>
          <p>Location 3: Lane Crawford, 2, Canton Road, Tsim Sha Tsui, Hong Kong.&nbsp;</p>
          <p>Location 4: Williams & Hill, Unit 9, Space Way, Feltham,&nbsp;</p>
          <p>Location 5: Deleted&nbsp;</p>
          <p>Location 6: Iterartis, Complexo Industrial da Granjal Armazem C20; 2625-607 Vialonga, Lisbon, Portugal&nbsp;
          </p>
          <p>Inception Date 12.00 Hours GMT 11th April 2022 to Expiry Date 12.00 Hours GMT 11th April 2023</p>
          <p><strong>Section 1: Stock&nbsp;</strong></p>
          <p>A: Whilst at: Locations 1-4: Total Sum Insured: GBP 2,500,000 any one <strong>event&nbsp;</strong>as per
            <strong>agreed value schedule&nbsp;</strong>attached (if applicable).&nbsp;&nbsp;Location 6: Total Sum Insured
            GBP 500,000. Any one event.
          </p>
          <p>B. Whilst removed for a&nbsp;<strong>temporary&nbsp;</strong>period: Total Sum Insured: GBP 300,000 any one
            <strong>event&nbsp;</strong>Territorial Limit: Worldwide&nbsp;
          </p>
          <p>C. Whilst in transit to/ from and at the following fairs: Not covered&nbsp;</p>
          <p>Basis of Settlement for Section 1 (owned stock): Cost price, plus 20%&nbsp;</p>
          <p>Deductible: GBP 500 any one <strong>event&nbsp;</strong></p>
          <p><strong>Section 2: Defective Title&nbsp;</strong></p>
          <p>Covered Limit of Liability: GBP 10,000 any one claim and in all for each&nbsp;</p>
          <p><strong>period of insurance&nbsp;</strong></p>
          <p>Deductible: nil&nbsp;</p>
          <p><strong>Section 3: Contents</strong>Location 1: Total Sum Insured: GBP 9,000 any one
            <strong>event,&nbsp;</strong>
          </p>
          <p>Specified Items: (as per attached listing) Laptops/Portable Equipment: GBP 4,000&nbsp;</p>
          <p>Deductible: GBP 500 any one <strong>event&nbsp;</strong></p>
          <p><strong>Section 4; Business Interruption</strong>Total Sum Insured: GBP 400,000 any one
            <strong>event&nbsp;</strong>Indemnity Period: 12 months&nbsp;
          </p>
          <p>Deductible: nil&nbsp;</p>
          <p><strong>Section 5: Buildings&nbsp;</strong></p>
          <p>Not covered&nbsp;</p>
          <p><strong>Section 6: Employer’s Liability&nbsp;</strong>Not covered&nbsp;</p>
          <p><strong>Section 7: Public & Products Liability</strong>Limit of liability: GBP 5,000,000 any one
            <strong>event&nbsp;</strong>and, in respect of Products Liability, for all
            <strong>events&nbsp;</strong>occurring during the <strong>period of insurance</strong>.&nbsp;
          </p>
          <p>Territorial Limit: Worldwide Deductible: Nil&nbsp;</p>
          <p><strong>Section 8: Personal Accident- Assault&nbsp;</strong></p>
          <p>Total Sum Insured: As per Wording&nbsp;</p>
          <p>Territorial Limit: Great Britain, Northern Ireland, Isle of Man and the Channel Islands&nbsp;</p>
          <p>Deductible: Nil&nbsp;</p>
          <p>ADDITIONAL CONDITIONS:&nbsp;</p>
          <ol>
            <li>Excluding losses arising from or involving dishonesty of Lane Crawford’s staff, management or
              directorate&nbsp;</li>
            <li>Unless&nbsp;<strong>stock&nbsp;</strong>is stored in a locked cabinet(s) and only one item is shown by any
              one shop assistant to any customer or potential customer at any one time, theft cover shall be limited to
              that following forcible and violent entry or exit only. This condition shall not apply to locations 1 and
              2.&nbsp;</li>
            <li>Minimum Standards of Security&nbsp;</li>
          </ol>
          <p>It is a condition precedent to <strong>our&nbsp;</strong>liability for loss destruction or damage by theft or
            any attempt thereat that the following minimum level of security (or alternative security protections as
            agreed in writing by <strong>us&nbsp;</strong>whether following a survey or otherwise) is installed at the
            <strong>premises&nbsp;</strong>and put into effect whenever the <strong>premises&nbsp;</strong>are left
            unattended&nbsp;
          </p>
          <ul>
            <li>a) &nbsp;the final exit door of the&nbsp;<strong>premises&nbsp;</strong>is to be fitted with a mortise
              deadlock which has 5 or more levers and/or conforms to BS3621 A matching boxed striking plate must be
              fitted&nbsp;</li>
            <li>b) all other external doors and all internal doors giving access to any part of the building not occupied
              by the Insured for the purpose of the Business are to be fitted with either&nbsp;</li>
          </ul>
          <p>i) a mortise deadlock which has 5 or more levers and/or confirms to BS3621 with a matched boxed striking
            plate as specified above&nbsp;</p>
          <p>or ii) two key operated security bolts for doors one fitted approximately 30cm from&nbsp;</p>
          <p>the top of the door and the other 30cm from the bottom&nbsp;</p>
          <p>c) aluminium or UPVC framed doors are to be fitted with integral cylinder key operated mortise
            deadlocks&nbsp;</p>
          <ul>
            <li>d) all opening external basement ground floor and other accessible (accessible being a window that can
              easily be reached such as a window adjacent to a flat roof or a fire escape balconies canopies or down
              pipes) windows fanlights rooflights and skylights are to be fitted with key operated window locks This
              requirement does not apply to windows protected by solid steel bars weld mesh or expanded metal grilles
              securely fixed to the brickwork surrounding the window&nbsp;</li>
            <li>e) any door or window officially designated a Fire Exit by a fire authority is excluded from the above
              requirements These are to be secured internally by panic bolts or fire exit bolts (capable of opening at all
              times) Any additional devices must be approved by the local Fire Prevention Officer&nbsp;</li>
          </ul>
          <p>4. Stillage Warranty All <strong>stock&nbsp;</strong>in basements shall be stored on pallets, shelving,
            racking or similar medium at least 150mm from floor level&nbsp;</p>
          <p>Coronavirus Absolute Exclusion Notwithstanding any other provision, no cover is provided under this policy
            for any claim, loss, liability, cost or expense of whatever nature directly or indirectly arising out of,
            contributed to by or resulting from coronavirus disease (COVID-19), severe acute respiratory syndrome
            coronavirus 2 (SARS-CoV- 2), or any mutation or variation thereof. This exclusion also applies to any claim,
            loss, cost or expense of whatever nature directly or indirectly arising out of, contributed to by or resulting
            from: (i) any fear or threat (whether actual or perceived) of; or (ii) any action taken in controlling,
            preventing, suppressing or in any way relating to any outbreak of; coronavirus disease (COVID-19), severe
            acute respiratory syndrome coronavirus 2 (SARS-CoV-2), or any mutation or variation thereof&nbsp;</p>
          <p><strong>APPENDIX 3</strong>&nbsp;</p>
          <p><strong>An example of a Notice of sale confirmation, issued on a resale</strong></p>
          <p>Please see payment&nbsp;confirmations for the below:</p>
          <p>- Refund Invoice&nbsp;0001: Hermes Birkin 30cm gold togo with gold hardware cost £11,438.49</p>
          <p>- Comm made £2,280.75 sold Invoice 0001 for £16k in Hong Kong</p>
          <p>Remittance:</p>
          <table>
            <tbody>
              <tr>
                <td>
                  <p>31 Mar 2021</p>
                </td>
                <td>
                  <p>BP</p>
                </td>
                <td>
                  <p>Mr xxxxxxxxxxx 0001</p>
                  <p>Mr xxxxxxxxxxx 0001</p>
                </td>
                <td>
                  <p>11,438.49</p>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>
                  <p>31 Mar 2021</p>
                </td>
                <td>
                  <p>BP</p>
                </td>
                <td>
                  <p>Mr xxxxxxxxx 0001 0001</p>
                  <p>Mr xxxxxxxxx 0001 0001</p>
                </td>
                <td>
                  <p>2,280.75</p>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <p><strong>APPENDIX 4</strong></p>
          <p><strong>Consignment Notice, issued on purchase</strong></p>
          <p>Consignment Notice:</p>
          <p>Consignee’s name Consigner’s name Date You the Consigner have left with me the Consignee the following items
            for [storage, safe keeping and onward sale]</p>
          <p>Description of item[s]. 1. 2. 3.&nbsp;</p>
          <p>We agree that the value assigned to each for Insurance purposes is;&nbsp;</p>
          <p>1. 2. 3.&nbsp;</p>
          <p>These amounts shall not exceed current market value.&nbsp;</p>
          <p>We agree that, in the event of Loss or damage occurring that is covered under our policy of Insurance with XL
            Catlin/Covea Insurance Co. Ltd. We are responsible to you.&nbsp;</p>
          <!-- <p>Signed for Consigner&nbsp;</p> -->
          <!-- <p>Signed for Consignee&nbsp;</p> -->
        </div>
        <!-- / Terms -->
      </v-card-text>
    </base-card>

    <base-card class="mt-5">
      <v-card-text style="padding-bottom: 10px !important">
        <!-- Checkbox -->
        <div>
          <v-checkbox class="py-0" v-model="termsAndConditionsAccepted"
            label="I accept the terms and conditions"></v-checkbox>
        </div>
        <!-- / Checkbox -->
      </v-card-text>
    </base-card>

    <!-- Actions -->
    <div class="actions">
      <v-btn dark @click.prevent="previous" class="mr-3">
        <v-icon left>mdi-chevron-left</v-icon>
        Previous
      </v-btn>

      <v-btn dark @click.prevent="next">Complete Setup
        <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <!-- / Actions -->
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  props: ["details"],
  data() {
    return {
      termsAndConditionsAccepted: false,
    };
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user'
    }),

    nameForContract() {
      if (this.details.account_type == 'business') {
        return this.details.business_name;
      }
      return this.user.name;
    },

    addressForContract() {
      let parts = [];

      if (this.details['address_line_one'].trim().length) {
        parts.push(this.details['address_line_one']);
      }

      if (this.details['address_line_two'].trim().length) {
        parts.push(this.details['address_line_two']);
      }

      if (this.details['city'].trim().length) {
        parts.push(this.details['city']);
      }

      if (this.details['postcode'].trim().length) {
        parts.push(this.details['postcode']);
      }

      if (this.details['country'].trim().length) {
        parts.push(this.details['country']);
      }

      return parts.join(', ');
    },

    commencementDate() {
      return moment().format("Do MMMM YYYY");
    }
  },
  methods: {
    previous() {
      this.$emit("previous");
    },

    next() {
      if (!this.termsAndConditionsAccepted) {
        this.$toast.error("Please accept the terms and conditions");
        return false;
      }

      this.$emit("save");
    },
  },
};
</script>

<style scoped lang="scss">
.actions {
  margin-top: 20px;
  margin-bottom: 20px;
}

b,
strong {
  font-weight: 600 !important;
}

table {
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid;
}

th, td {
  padding: 10px;
  vertical-align: middle !important;

  p:last-child {
    margin-bottom: 0;
  }
}
</style>
