<template>
  <div>
    <!-- Account Type -->
    <base-card>
      <v-card-text>
        <div class="pa-2">
          <b>Are you acting as an individual or a business?</b>
          <v-radio-group v-model="detailsLocal.account_type" row>
            <v-radio label="Individual" value="individual" />
            <v-radio label="Business" value="business" />
          </v-radio-group>
        </div>
      </v-card-text>
    </base-card>
    <!-- / Account Type -->

    <!-- Business Name -->
    <base-card class="mt-5" v-if="detailsLocal['account_type'] == 'business'">
      <v-card-text>
        <div class="pa-2">
          <b>What is your business name?</b>
          <v-text-field label="Business name" v-model="detailsLocal.business_name" />
        </div>
      </v-card-text>
    </base-card> 
    <!-- / Business Name -->

    <!-- Actions -->
    <div class="actions">
      <v-btn dark @click.prevent="previous" class="mr-3">
        <v-icon left>mdi-chevron-left</v-icon>
        Previous
      </v-btn>

      <v-btn dark @click.prevent="next">Next
        <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <!-- / Actions -->
  </div>
</template>

<script>
export default {
  props: ["details"],
  data() {
    return {
      detailsLocal: {
        ...this.details,
      }
    };
  },
  methods: {
    previous() {
      this.$emit("previous");
    },

    next() {
      if (this.detailsLocal["account_type"] == null) {
        this.$toast.error("Please select either individual or business");
        return false;
      }

      if (
        this.detailsLocal["account_type"] == 'business' 
        && (
          this.detailsLocal['business_name'] == null
          || this.detailsLocal['business_name'].trim().length == 0
        )
      ) {
        this.$toast.error("Please provide your business name");
        return false;
      }

      this.$emit("update-details", this.detailsLocal);
      this.$emit("next");
    },

    isSelected(value) {
      return this.detailsLocal["account_type"] === value;
    },

    selectOption(value) {
      this.detailsLocal["account_type"] = value;
    },

    setShowDescriptionFor(val) {
      this.showDescriptionFor = val;
    },
  },
};
</script>

<style scoped lang="scss">
.card-select {
  background: #e6f2fc !important;

  &:hover {
    background: #daebfb !important;
  }
}

.actions {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>